import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faPaste, faPlusCircle, faArrowCircleUp, faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuid } from 'uuid';

const RecipeControls = (props) => {
    return  <div className="recipe-control-wrapper">
                <div className="recipe-controls">
                    <div className="recipe-control-item" onClick={() => props.addTab({name:"Tab Name", id: uuid()})} >
                        <FontAwesomeIcon icon={faPlusCircle} onClick={() => props.addTab({name:"Tab Name", id: uuid()})} />
                    </div>
                    <div className="recipe-control-item"  onClick={() => props.uploadTab() }>
                        <FontAwesomeIcon icon={faArrowCircleUp} onClick={() => props.uploadTab() } />
                    </div>
                    <a className="recipe-control-item" href={"/api/download/" + props.tabIndex}>
                        <FontAwesomeIcon icon={faArrowCircleDown}/>
                    </a>
                    <div className="recipe-control-item" onClick={() => props.addTab({name:"Tab Name", id: uuid(), type:"camera"})} >
                        <FontAwesomeIcon icon={faCamera} onClick={() => props.addTab({name:"Tab Name", id: uuid(), type:"camera"})} />
                    </div>
                    <div className="recipe-control-item" onClick={() => props.addTab({name:"Tab Name", id: uuid(), type:"clip", data:[]})} >
                        <FontAwesomeIcon icon={faPaste} onClick={() => props.addTab({name:"Tab Name", id: uuid(), type:"clip", data:[]})} />
                    </div>
                </div>
                <div className="status-message">
                    {props.status}
                </div>
            </div>
}

export default RecipeControls;
import React, { useRef } from 'react';
import CheckList from './CheckList';
import ContentEditable from 'react-contenteditable';
import sanitizeHtml from 'sanitize-html';

const initTab = parts => {
    let ret = "";
    parts.forEach(v => {
        if(v.type === "link")
            ret += "<div class=\"recipe-ingredient-link\">" + v.name + "</div>";
        else
            ret += "<div class=\"recipe-ingredient\">" + v.name + "</div>";
    })
    return ret;
}

const sanitizeConfig = {
    allowedTags: ["div", "span"],
    allowedAttributes: { div: ["class", "onclick"], span: ["class"] }
}

const Ingredients = (props) => {
    const name = props.name.toLowerCase();
    const editRef = useRef();
    const txtRef = useRef(props.tab.data && props.tab.data[name] ? sanitizeHtml(initTab(props.tab.data[name]), sanitizeConfig) : props.name);
    
    const updateTab = () => {
        if(txtRef.current.indexOf("</div>"))
        {
            props.updateTab({...props.tab, data: {...props.tab.data, [name]: [{name: txtRef.current, type:"object"}]}});
            return ;
        }

        var parts = txtRef.current.split("</div>").filter(v => v.trim().length);
        props.updateTab({...props.tab, data: {...props.tab.data, [name]: parts.length ? parts.map(v => {
            var p = v.split(">");
            return ({name: p[1], type: p[0].indexOf("ingredient") > -1 ? "object" : p[0].indexOf("<div") > -1 ? "link" : "object"});
        }) : []}})
    }

    if(!props.edit)
        return <CheckList items={props.tab.data[name]} setTabID={props.setTabID}/>
    
    return  <ContentEditable
                className={name === "ingredients" || name === "instructions" ? "double-part" : "recipe-part"}
                innerRef={editRef}
                disabled={!props.edit}
                html={txtRef.current}
                onChange={e => txtRef.current = e.target.value}
                onBlur={e =>  { updateTab(); } }
            />
}

export default Ingredients;
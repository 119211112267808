import React from 'react';
import FileItem from './FileItem';
import { v4 as uuid } from 'uuid';

const FileTab = (props) => {
    let items = [];
    props.tab.data.forEach((v, i) => items.push(<FileItem key={uuid()} item={v} itemIndex={i} tab={props.tab} updateTab={props.updateTab} />));
    return  <div className="file-tab">
                {items}
            </div>
}

export default FileTab;
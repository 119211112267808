import React from 'react';
import Webcam from 'react-webcam';
import { v4 as uuid } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const CameraTab = (props) => {    
    const vconstraints = {
        facingMode: "environment"
    }
    const webcamRef = React.useRef(null);
    const capture = React.useCallback(() => {
        //Capture
        const img = webcamRef.current.getScreenshot({height: 1080, width: 1900});
         
        //Copy
        let t = {...props.tab};
        if(!Array.isArray(t.data))
            t.data = [];
        t.data.push(img);

        //Update
        props.updateTab(t);
    }, [props, webcamRef]);

    const getThumbs = images => {
        if(!Array.isArray(images))
            return null;
        
        let ret = [];
        images.forEach((v, i) => {
            ret.push(   <div className="camera-thumb" key={uuid()}>
                            <img className="thumb" src={v} alt={"thumb " + (i + 1)}/>
                            <div className="thumb-label">
                                Preview {(i + 1)}
                                <FontAwesomeIcon icon={faTimesCircle} onClick={ () => { let t = {...props.tab}; t.data.splice(i, 1); props.updateTab(t); }} />
                            </div>
                        </div>);
        });
        return ret;
    }

    return  <div className="camera-tab">
                <div className="camera-preview">
                    <Webcam
                        className="camera-item"
                        audio={false}
                        width={200}
                        height={150}
                        ref={webcamRef}
                        screenshotFormat="image/png"
                        videoConstraints={vconstraints}
                    />
                    <button className="camera-button" onClick={capture}>{"Capture"}</button>
                </div>
                <div className="camera-thumbs">
                    {getThumbs(props.tab.data)}
                </div>
            </div>
}

export default CameraTab;
import React, { useRef } from 'react';
import ContentEditable from 'react-contenteditable'; 
import { v4 as uuid } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const FileItem = (props) => {
    const editRef = useRef();
    const txtRef = useRef(props.item.name);
    
    const removeFile = name => {
        let ii = -1;
        let jj = -1;
        let tab = {...props.tab};
        tab.data.forEach((v, i) => {
            if(ii === -1)
            {
                v.files.forEach((vv, j) => {
                    if(ii === -1 && vv.name === name)
                    {
                        ii = i;
                        jj = j;
                    }
                })
            } 
        });
        if(ii > -1)
        {
            tab.data[ii].files.splice(jj, 1);
            if(tab.data[ii].files.length === 0)
                tab.data.splice(ii, 1);
        }    
        props.updateTab(tab);
    }

    const moveFile = e => {
        let name = e.dataTransfer.getData("text");
        let tab = {...props.tab};
        let ii = -1;
        let jj = -1;
        
        tab.data.forEach((v, i) => {
            if(ii === -1)
            {
                v.files.forEach((vv, j) => {
                    if(ii === -1 && vv.name === name)
                    {
                        ii = i;
                        jj = j;
                    }
                })
            } 
        });
        if(ii > -1)
        {
            let d = tab.data[props.itemIndex];
            let trans = tab.data[ii].files.splice(jj, 1);
            if(tab.data[ii].files.length === 0)
                tab.data.splice(ii, 1);
            d.files.push(trans[0]);
        }  
        props.updateTab(tab);
    }

    const getList = files => {
        let ret = [];
        files.forEach((v, i) => {
            ret.push(   <div 
                            className="file-drag-item"
                            key={uuid()}
                            draggable="true"
                            onDragStart={e => e.dataTransfer.setData("text", v.name) }
                        >
                            {v.name}
                            <FontAwesomeIcon icon={faTimesCircle} onClick={ () => removeFile(v.name) } />
                        </div>);
        });
        return ret;
    }
    
    return  <div 
                className="file-item"
                onDragEnter={ e => e.preventDefault() } 
                onDragOver={ e => e.preventDefault() }         
                onDrop={ e => { e.stopPropagation(); e.preventDefault(); moveFile(e); } }
            >
                <div className="file-header">
                    <ContentEditable
                        className="file-name"
                        innerRef={editRef}
                        html={txtRef.current}
                        onChange={e => txtRef.current = e.target.value}
                    />
                </div>
                <div className="file-tabname">
                    {getList(props.item.files)}
                </div>
            </div>
}

export default FileItem;
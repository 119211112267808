import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import RecipeListItem from './RecipeListItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons';

const GetResults = (results, filter, addTab, setStatus) => {
    let res = [];
    
    results.forEach((v, i) => {
        if(filter && filter.length)
        {
            if(v.name.toLowerCase().indexOf(filter.toLowerCase()) > -1)
                res.push(<RecipeListItem key={i} name={v.name} rid={v.id} addTab={addTab} setStatus={setStatus} />);
        }
        else
            res.push(<RecipeListItem key={i} name={v.name} rid={v.id} addTab={addTab} setStatus={setStatus}/>);
    });

    return res;
}

const list = (setResults) => {
    axios
        .post("/api/list")
        .then(data => {
            console.log(data.data)
            setResults(data.data);
        })
        .catch(err => console.log(err));
}

const SearchPanel = (props) => {
    const txtRef = useRef();
    const [filter, setFilter] = useState("");
    const [results, setResults] = useState([]);
    useEffect(() => {
        txtRef.current && txtRef.current.focus();
        list(setResults);
    },[]);

    const refresh = () => {
        props.setStatus("Refreshing Recipes");
        list(setResults);
        props.setStatus("");
    }

    const processKey = () => {
        if(txtRef.current) 
            setFilter(txtRef.current.value);
    }

    return  <div className="search">
                <div className="search-panel">
                    <input type="text" className="search-input" placeholder="Search" onKeyUp={() => processKey() } ref={txtRef}/>
                    <div className="search-refresh" onClick={() => refresh() }><FontAwesomeIcon icon={faRedo} onClick={() => refresh()}/></div>
                </div>
                <div className="search-results">
                    {GetResults(results, filter, props.addTab, props.setStatus)}
                </div>
            </div>
}

export default SearchPanel;
import React, { useState } from 'react';

import Toggle from 'react-toggle';

import CameraTab from './CameraTab';
import Clipboard from './ClipboardTab';
import FileTab from './FileTab';
import HTMLTab from './HTMLTab';
import ImageTab from './ImageTab';
import RecipePart from './RecipePart';

import 'react-toggle/style.css';
import Ingredients from './Ingredients';

const RecipeTab = (props) => {

    console.log(props)
    const [edit, setEdit] = useState(props.editMode ? props.editMode : props.tab.type === "json" ? false : true);

    if(props.tab.type === "camera")
        return <CameraTab tab={props.tab} updateTab={props.updateTab} />

    if(props.tab.type === "clip")
        return <Clipboard tab={props.tab} updateTab={props.updateTab} />

    if(props.tab.type === "files")
        return <FileTab tab={props.tab} updateTab={props.updateTab} />

    if(props.tab.type === "image")
        return <ImageTab tab={props.tab} />;
    
    if(props.tab.type === "pdf")
        return <iframe className="recipe-pdf" title="recipe pdf" src={props.tab.data}/>;

    if(props.tab.type === "html")
        return <HTMLTab text={props.tab.data} />

    return  <div className="recipe-tab">
                <div className="recipe-header">
                    <Toggle className="header-toggle" aria-label="edit mode toggle" icons={false} onChange={(e)=> setEdit(e.target.checked)} checked={edit}/>
                    <div className="header-toggle-label">Edit Mode</div>
                </div>
                <Ingredients name="Ingredients" tab={props.tab} edit={edit} setTabID={props.setTabID} updateTab={props.updateTab}/>
                <RecipePart name="Instructions" tab={props.tab} edit={edit} updateTab={props.updateTab}/>
                <RecipePart name="Keywords" tab={props.tab} edit={edit} updateTab={props.updateTab}/>
                <RecipePart name="Source" tab={props.tab} edit={edit} updateTab={props.updateTab}/>
            </div>
}

export default RecipeTab;

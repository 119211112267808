import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';

const ImageTab = (props) => {
    const [index, setIndex] = useState(0);

    const getThumbs = images => {
        if(!Array.isArray(images))
                return null;
            
            let ret = [];
            images.forEach((v, i) => {
                ret.push(<div className="image-thumb" key={uuid()}>
                            <img className="thumb" src={v} alt={"thumb " + (i + 1)} onClick={() => setIndex(i) }/>
                         </div>);
            });
            return ret;
    }
    
    return  <div className="image-tab">
                <div className="image-view">
                    <img className="image-item" src={props.tab.data[index]} alt={"Image " + index}/>
                </div>
                <div className="image-thumbs">
                    {getThumbs(props.tab.data)}
                </div>
            </div>
}

export default ImageTab;
import React, { useRef } from 'react';
import ContentEditable from 'react-contenteditable';
import sanitizeHtml from 'sanitize-html';

const sanitizeConfig = {
    allowedTags: ["div", "span"],
    allowedAttributes: { div: ["class"], span: ["class"] }
}

const initTab = props => {
    console.log(props)
    let ret = "";
    if(Array.isArray(props))
    {
        props.forEach(v => {
            if(v.trim().length !== 0)
                ret += "<div class=\"recipe-paragraph\">" + v + "</div>";
        })
    }
    else
        ret = props;
    return ret;
}

const RecipePart = (props) => {
    const name = props.name.toLowerCase();

    console.log(props)

    const editRef = useRef();
    const txtRef = useRef(props.tab.data && props.tab.data[name] ? sanitizeHtml(initTab(props.tab.data[name]), sanitizeConfig) : props.name);
    
    const updateTab = () => {
        if(txtRef.current.indexOf("</div>"))
        {
            props.updateTab({...props.tab, data: {...props.tab.data, [name]: [txtRef.current]}});
            return ;
        }
        var parts = txtRef.current.split("</div>").filter(v => v.trim().length);
        props.updateTab({...props.tab, data: {...props.tab.data, [name]: parts.length ? parts.map(v => {
            var p = v.split(">");
            return p[1];
        }) : []}})
    }
    return  <ContentEditable
                className={name === "ingredients" || name === "instructions" ? "double-part" : "recipe-part"}
                innerRef={editRef}
                disabled={!props.edit}
                html={txtRef.current}
                onChange={e => txtRef.current = e.target.value}
                onBlur={() => { updateTab() }}
            />
}

export default RecipePart;

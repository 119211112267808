import React from 'react';
import axios from 'axios';

const fetchRecipe = (id, addTab, setStatus) => {
    axios.get("/api/" + id)
            .then(res => {
                addTab(res.data);
                setStatus("");
            })
            .catch(err => console.log(err));
}

const RecipeListItem = (props) => {
    return  <div className="recipe-list-item" onClick={ e => { props.setStatus("Fetching " + props.name); fetchRecipe(props.rid, props.addTab, props.setStatus);} }>
                {props.name}
            </div>
}


export default RecipeListItem;
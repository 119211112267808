import React, { useCallback, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const blobToBase64 = blob => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

const ClipboardTab = (props) => {    
    const [index, setIndex] = useState(0);
    const capture = useCallback(() => {
        navigator.clipboard.read().then(items => {
            let t = {...props.tab};
            items.forEach(v => {
                v.types.forEach(vv => {
                    if(vv === "image/png")
                    {
                        v.getType("image/png")
                            .then(blob => blobToBase64(blob))
                            .then(res => {
                                t.data.push(res);
                                props.updateTab(t);
                            });                        
                    }
                });
            });
      });
    }, [props]);

    const getThumbs = images => {
        if(!Array.isArray(images))
            return null;
        
        let ret = [];
        images.forEach((v, i) => {
            ret.push(   <div className="camera-thumb" key={uuid()} onClick={ () => setIndex(i)} >
                            <img className="thumb" src={v} alt={"thumb " + (i + 1)} />
                            <div className="thumb-label" onClick={ () => setIndex(i)} >
                                Preview {(i + 1)}
                                <FontAwesomeIcon icon={faTimesCircle} onClick={ () => setIndex(i)} />
                            </div>
                        </div>);
        });
        return ret;
    }

    return  <div className="camera-tab">
                {
                    props.tab.data[index] &&   
                    <div className="clip-view">
                        <img className="image-item" src={props.tab.data[index]} alt={"Image " + index}/>
                    </div>
                }
                <div className="camera-preview">
                    <button className="camera-button" onClick={capture}>{"Get Clipboard"}</button>
                </div>
                <div className="camera-thumbs">
                    {getThumbs(props.tab.data)}
                </div>
            </div>
}

export default ClipboardTab;
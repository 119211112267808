import React, { useState } from 'react';

//Pass in info to find tab and switch but not implemented now...

const CheckListItem = (props) => {
    const[check, setCheck] = useState(0);

    return  <div className={check ? "checklist-item-check" : "checklist-item-unchecked"} onClick={() => setCheck(check === 1 ? 0 : 1)}>
                {props.text}
            </div>
}

export default CheckListItem;
import React from 'react';
import { v4 as uuid } from 'uuid';
import CheckListItem from './CheckListItem';

const getItems = (items, setTabID) => {
    if(items === undefined)
        return null;
    
    let ret = [];
    items.forEach(v => {
        if(v.type === "link")
            ret.push(<CheckListItem key={uuid()} text={v.name} oid={v.id} setTabID={setTabID} />);
        else
            ret.push(<CheckListItem key={uuid()} text={v.name} />);
    });

    return ret;
}

const CheckList = (props) => {
    return  <div className="double-part checklist">
                {getItems(props.items, props.setTabID)}
            </div>;
}

export default CheckList;
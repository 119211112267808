import React, { useRef } from 'react';
import RecipeTab from './RecipeTab';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuid } from 'uuid';
import 'react-tabs/style/react-tabs.css';

const doPrompt = (tab, updateTab) => {
    var title = prompt("New tab name", tab.name);
    if(title && title !== tab.name)
    {
        var t = {...tab};
        t.name = title;
        updateTab(t);
    }
}

const RecipeViewer = (props) => {

    const closeRef = useRef(0);

    if(!props.tabs)
        return null;

    let buttons = [];
    let recipes = [];
    let keys = Object.keys(props.tabs);
    
    keys.forEach( v => {
        buttons.push(<Tab href={"#" + v.id} key={uuid()} className={"recipe-tab"} selectedClassName={"recipe-tab-selected"} onDoubleClick={() => {doPrompt(props.tabs[v], props.updateTab)}}>{props.tabs[v].name} <FontAwesomeIcon icon={faTimesCircle} onClick={ () => { closeRef.current = 1; props.removeTab(v) }} /></Tab>)
        recipes.push(<TabPanel key={uuid()}><RecipeTab tab={props.tabs[v]} editMode={props.editMode} updateTab={props.updateTab} setTabID={props.setTabID} /></TabPanel>);
    });

    return  <div className="recipe-viewer">
                <Tabs selectedIndex={keys.indexOf(props.tabIndex)} onSelect={index => { if(closeRef.current === 0) props.setTabID(keys[index]); closeRef.current = 0; } } >  
                    <TabList className="tab-list">
                        {buttons}
                    </TabList>
                    {recipes}
                </Tabs>
            </div>
}

export default RecipeViewer;
import React, { useRef } from 'react';
import ContentEditable from 'react-contenteditable';

const HTMLTab = (props) => {
    const txtRef = useRef(props.text);
    const editRef = useRef();

    return  <ContentEditable
                className="recipe-html"
                innerRef={editRef}
                disabled={!props.edit}
                html={txtRef.current}
                onChange={e => txtRef.current = e.target.value}
                onBlur={e => {} }
            />
}

export default HTMLTab;
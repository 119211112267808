import React, { useEffect, useState } from 'react';
import SearchPanel from './Selection/SearchPanel';
import Recipes from './Recipes';
import { v4 as uuid } from 'uuid';
import './App.css';
import axios from 'axios';

const addTabs = (tabs, t) => {
  let ts = {...tabs};
  ts[t.id] = t;
  return ts;
}

const removeTab = (tabs, id) => {
  let ts = {...tabs};
  delete ts[id];
  return ts;
}

const updateTabs = (tabs, t) => {
  let ts = {...tabs};
  ts[t.id] = t;
  return ts;
}

const doUpload = (tab, remove, update, setStatus) => {
  let t = {...tab};
  
  if(tab.name === "Tab Name")
  {
    var title = prompt("New tab name", tab.name);
    if(title && title !== tab.name)
        t.name = title;
    else
    {
      alert("Upload cancelled. Please select a new tab name");
      setStatus("");
      return;
    }
  }

  if(t.type === "files")
  {
    remove(tab);
    t.data.forEach(v => {

      let data = new FormData();
      data.append("name", v.name);
      v.files.forEach(f => data.append("file", f))
      axios
        .put("/api/upload", data)
        .then(res => { update(res.data) ; setStatus(""); } )
        .catch(err => console.log(err));
    })
  }
  else
  {
    console.log(t);
    axios
      .post("/api/upload", t)
      .then(res => {
        update(res.data);
        setStatus("");
      })
      .catch(err => { console.log(err); console.log(err.response)});
  }
}

const processDrop = (e, tab, updateTab) => {
  var files = e.dataTransfer.files;
  var t = tab === undefined ? {} : {...tab};
  if(files.length)
  {
    if(t.name === undefined)
    {
      t.id = uuid();
      t.name = "Files";
    }
    t.type = "files";
    t.data = [];
    [...files].forEach(v => { 
      let item = {};
      item.name = v.name.substring(0, v.name.indexOf("."));
      item.files = [];
      item.files.push(v);
      t.data.push(item);
    });
    updateTab(t);
  }
}

const App = () => {
  const[status, setStatus] = useState("");
  const[tabID, setID] = useState("");
  const[pID, setPID] = useState("");
  const[tabs, setTabs] = useState({});
  const[editMode, setEditMode] = useState();

  useEffect(() => {document.title = "iRecipe"})

  const hasID = (ts, t) => {
    Object.keys(ts).forEach(k => {
      if(ts[k].id === t.id)
        return true;
    })
    return false;
  }

  const add = t => {
    let id = t.tabs ? t.tabs[0].id : t.id;
    if(t.tabs) 
    {
      let ts = {...tabs};
      t.tabs.forEach(v => {
        if(!hasID(ts, v))
          ts[v.id] = v;
      });
      setTabs(ts);
    }
    else
    {
      if(!hasID(tabs, t))
        setTabs(addTabs(tabs, {...t}));
    }
    setID(id);
  }

  const rem = id => {  
    setTabs(removeTab(tabs, id));
    if(tabID === id)
      setID(pID);
  }

  const set = id => {
    if(id !== tabID)
      setEditMode(undefined);
    setPID(tabID);
    setID(id);
  }

  const update = t => {
    setTabs(updateTabs(tabs, t));
    set(t.id);
    setEditMode(true);
  }

  const upload = () => {
    let tab = {...tabs[tabID]};
    setStatus("Uploading " + tab.name);
    
    if(tab.type === "camera")
      tab.type = "image";
    else if(tab.type === "clip")
      tab.type = "image";
    else if(tab.type === undefined)
      tab.type = "json";

    doUpload(tab, rem, update, setStatus);
  }

  return  <div className="irecipe"
            onDragEnter={ e => e.preventDefault() } 
            onDragOver={ e => e.preventDefault() }         
            onDrop={ e => { 
                e.preventDefault(); 
                processDrop(e, tabs[tabID], update)
              } 
            }
          >
            <SearchPanel addTab={ t => add(t) } setStatus={setStatus} />
            <Recipes 
                  tabs={tabs} 
                  tabIndex={tabID} 
                  status={status}
                  addTab={ t =>  add(t) } 
                  removeTab={ id => rem(id) }
                  setTabID={ id => set(id) } 
                  setStatus={setStatus}
                  updateTab={ t => update(t) } 
                  uploadTab={ () => upload() }
                  editMode={editMode}
            />
          </div>
}

export default App;

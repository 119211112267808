import React from 'react';
import RecipeControls from './RecipeControls';
import RecipeViewer from './RecipeViewer';

const Recipes = (props) => {
    return  <div className="recipes">
                <RecipeControls {...props} />
                <RecipeViewer {...props} />
            </div>
}

export default Recipes;